<i18n>
{
  "de": {
    "modalTitle": "Kategorisierung"
  }
}
</i18n>

<template>
  <Modal class="c-edit-tag-group-modal" @close="$emit('close', $event)">
    <template #header>
      <h2>{{ $t('modalTitle') }}</h2>
    </template>

    <div>
      <EditTagGroup :portfolio="portfolio" :tag-group="tagGroup" @cancel="onCancel" @save="onSave" />
      <EditTagGroupTags v-if="tagGroupId !== null" :tag-group="tagGroup" :portfolio="portfolio" />
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/shared/Modal.vue'
import EditTagGroup from '@/components/settings/tag_groups/EditTagGroup.vue'
import EditTagGroupTags from '@/components/settings/tag_groups/EditTagGroupTags.vue'

export default {
  components: {
    Modal,
    EditTagGroup,
    EditTagGroupTags,
  },

  props: {
    portfolio: {
      type: Object,
    },
    tagGroupId: {
      type: Number,
    },
  },

  computed: {
    tagGroup() {
      return this.portfolio.tag_groups.find((g) => g.id === this.tagGroupId)
    },
  },

  methods: {
    onCancel() {
      if (this.tagGroupId === null) {
        // Close dialog if adding new is canceled
        this.$emit('close')
      }
    },

    onSave() {
      if (this.tagGroupId === null) {
        // Close dialog if adding new is saved
        this.$emit('close')
      }
    },
  },
}
</script>
